@media (max-width: 650px) {

  .inputText.err,
  .twoinputconteiner .inputText.err {
    border: #bb1313 1px solid;
    animation: ShakeOnError 0.4s linear;
  }

  @keyframes ShakeOnError {
    0% {
      transform: translate(20px);
    }

    20% {
      transform: translate(-20px);
    }

    40% {
      transform: translate(10px);
    }

    60% {
      transform: translate(-10px);
    }

    80% {
      transform: translate(5px);
    }

    100% {
      transform: translate(0px);
    }
  }

  .inputText.err::placeholder,
  .twoinputconteiner .inputText.err::placeholder {
    color: #FFFFFF;
  }

  body {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    max-height: stretch;
    max-height: -webkit-fill-available;
  }

  html {
    height: stretch;
    height: -webkit-fill-available;
  }


  header {
    background-color: #000;
    padding: 1vh;
    display: flex;
    justify-content: center;
  }



  .HomeMain {
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Chivo', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    color: #E3B225;
    text-align: center;
  }


  header img {
    height: 13vh;
  }

  header a {
    height: 13vh;
  }


  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  a {
    margin: 0;
    padding: 0;
  }



  .subTitle {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: #ffffff;
    font-size: 1.25rem;

    text-align: center;
  }

  @media (min-width: 768px) {

    h1,
    .h1 {
      font-size: 4rem;
    }


  }



  .CallToAction {
    padding: 1rem 4rem;
    font-size: 1.25rem;
    border-radius: 0.2rem;
    border: #F5BD33 solid 1px;
    background-color: rgba(33, 33, 33, .5);
    font-family: 'Akshar', sans-serif;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }


  footer {
    min-height: 13vh;
    padding-top: 2vh;
    height: auto;
    background-color: #000;
    color: #fff;
    box-shadow: 0px -5px 12px 0px #AE891E33;

  }

  .footerConteiner {
    background: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
  }

  .footerConteiner .column {
    flex-basis: 30%;
    display: flex;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: 'Akshar', sans-serif;


  }

  .footerConteiner .column img {
    width: 24px;
    height: 24px;
    margin: 10px;
    margin-top: 0px;
  }

  .footerConteiner .column a {
    color: #ffffff;
  }

  .footerConteiner .column b {
    font-weight: 300;
  }

  .column p {
    margin: 0;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: #ffffff;
    font-size: 1em;
  }

  @media(max-width: 768px) {
    .footerConteiner .column {
      flex-basis: 100%;

    }
  }

  .MainTurnos {

    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;

  }



  .ProgressBarConteiner {
    width: 90%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7.5%;

  }

  h2 {
    font-size: 1.75rem;
    color: #F5BD33;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
    text-align: center;
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.75rem;
    color: #F5BD33;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
  }


  h3.TitleTurnos {
    text-align: center;
    color: #fff;
    border-bottom: #AE891E 1px solid;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5px;
    font-size: 1.75rem;
    white-space: nowrap;
  }

  .TurnoMainSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }





  .numberBlock {
    width: 62.5vw;
    display: flex;
    justify-content: space-between;
  }

  .numberProgressBar {
    padding: 0;
    height: 12.5vw;
    width: 12.5vw;
    border-radius: 100%;
    border: 2px #E3B225 solid;
    background: #121212;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.35rem;
  }


  .numberProgressBar.Active {
    background-color: #E3B225;
    color: #000;
  }

  .numberProgressBar.Finish {
    border: solid 2px #AE891E;
    background-color: #AE891E;
  }

  .numberProgressBar .FinishEfect {
    opacity: 0%;
    transition: all .45s;
    position: absolute;

  }

  .numberProgressBar.Finish .FinishEfect {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #AE891E;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100%;
  }

  .FinishEfect img {
    width: 50%;
    height: auto;
  }


  .lineOutside {
    position: absolute;
    height: 2px;
    transform: translate(100%, 100%);
    width: 100%;
    background-color: #E3B225;
  }

  .nameProgressBarButton {
    font-family: 'Akshar';
    position: absolute;
    max-height: 5px;
    transform: translate(0%, 800%);
    color: #fff;
  }



  .conteinerBottons {
    width: 100%;
    height: 10.5vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .conteinerBottons.confirmation {
    width: 100%;
    height: 15.5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }


  .conteinerBottons.confirmation button {
    margin: 0 auto;
    padding: 0;
    width: 90%;
    height: 40%;
    font-family: 'Akshar';
    text-align: center;
    font-size: 1rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .conteinerBottons button {
    margin: 0 auto;
    padding: 0;
    width: 45%;
    height: 55%;
    font-family: 'Akshar';
    text-align: center;
    font-size: 1rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .conteinerBottons button a {
    width: 100%;
    height: 100%;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .conteinerBottons button.back {
    border: 1px #fff solid;
    border-radius: 3.2px;
  }


  .conteinerBottons button.next {
    border: 1px #E3B225 solid;
    border-radius: 3.2px;
    transition: all 0.15s;
  }

  .next:hover {
    background-color: #E3B225;
    color: black;
  }

  .contienerSchedule {
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;

    width: 100%;
    height: 60vh;
    display: grid;
    grid-template-columns: repeat(3, 29.75%);
    grid-template-rows: repeat(8, 10.5%);
    grid-column-gap: 5.375%;
    grid-row-gap: 2.25%;
    justify-content: center;
  }

  .contienerSchedule button {
    border: #E3B225 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Chivo', sans-serif;
    cursor: pointer;
    background: none;
    font-size: .95rem;
    border-radius: 3.2px;
  }

  .contienerSchedule button:disabled {
    background-color: #898989;
    border: 1px solid #898989;
    color: #B7B7B7;
    cursor: default;
  }

  .contienerSchedule button.Select:disabled {
    background: none;
    background-color: #898989;
    border: 1px solid #898989;
    color: #B7B7B7;
    cursor: default;
  }

  .contienerSchedule button.Select {
    background: linear-gradient(0deg, #E3B225, #E3B225),
      linear-gradient(0deg, #CC9E17, #CC9E17);
    color: #121212;
  }

  .continerForm {
    margin-top: 2.5%;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: none;
  }

  .conteinerFormMovil {
    margin-top: 2.5%;
    margin-bottom: 2.5vh;

    height: 75%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .inputText {
    width: 92.5%;
    height: 6.5vh;
    border: #E3B225 1px solid;
    background: none;
    font-family: 'Akshar', sans-serif;
    padding-left: 2.5%;
    padding-right: 2.5%;
    font-size: 1.1rem;
    color: #fff;
    margin-bottom: 5%;

  }

  .twoinputconteiner {
    width: 95.5%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5%;

  }

  .twoinputconteiner .inputText {
    width: 100%;
    height: 100%;
    border: #E3B225 1px solid;
    background: none;
    font-family: 'Akshar', sans-serif;
    font-size: 1.1rem;
    color: #fff;

  }


  .continerForm label,
  .conteinerFormMovil label {
    color: #fff;
    width: 92.5%;
    font-family: 'Akshar', sans-serif;
    text-align: start;
    margin-bottom: 1%;
    white-space: nowrap;
  }



  .continerInput {
    height: 6.5vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .continerInput label {
    position: absolute;
    transform: translateY(-120%);



  }

  .bookingDetail {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
    font-family: 'Akshar', sans-serif;
  }

  h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;

    text-align: center;
    font-size: 1.17em;
    margin: 0;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 400;
    text-align: start;
    width: 92.5%;
    margin-top: 5%;
    margin-bottom: 1.5%;
  }


  .conteinerCheckbox {
    width: 90%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 1vh;

  }



  .conteinerCheckbox .checkbox {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 5px;
    appearance: none;
    border: 2px solid #fff;
    position: relative;
  }

  .conteinerCheckbox .checkbox:checked:after {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    content: '';
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
  }


  .untiCheboxAndLabel {
    margin-top: 1vh;
    margin-bottom: 1vh;
    background: none;
    border: none;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
  }

  .conteinerCheckbox label {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin: 0;
    width: auto;
    padding-left: 5%;
  }

  .bookingDetail {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
    font-family: 'Akshar', sans-serif;
  }

  h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;

    text-align: center;
    font-size: 1.17em;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 500;
    line-height: 18px;
  }

  .Aclaracion {
    width: 100%;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    margin-bottom: 5%;
  }

  .cardConfirm {
    padding-top: 5%;
    padding-bottom: 5%;

    margin-top: 2.5%;
    width: 90%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    height: auto;
    border: 1px solid #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .KeyWord {
    margin-top: 5.5%;
    font-size: 2.5rem;
    font-family: Akshar;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
  }

  .confirmDetalle {
    color: #FFFFFF;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    max-width: 90%;
  }

  .ConfirmTitle {
    font-family: Akshar;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    color: #FFFFFF;
    margin: 1%;
  }

  .conteinerRef {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
  }

  .circleRefConteiner {
    font-family: 'Akshar';
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 1vh;

  }

  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 2.5%;
  }

  .available .circle {
    border: #E3B225 1px solid;
  }

  .disabled .circle {
    background: linear-gradient(0deg, #898989, #898989),
      linear-gradient(0deg, rgba(184, 184, 184, 0.2), rgba(184, 184, 184, 0.2));
    border: 1px solid #898989
  }

  .Select .circle {
    background: #E3B225;
    border: 1px solid #E3B225;
  }
}

@media (min-width: 651px) and (max-width: 900px) {

  .inputText.err,
  .twoinputconteiner .inputText.err {
    border: #bb1313 1px solid;
    animation: ShakeOnError 0.4s linear;
  }

  @keyframes ShakeOnError {
    0% {
      transform: translate(20px);
    }

    20% {
      transform: translate(-20px);
    }

    40% {
      transform: translate(10px);
    }

    60% {
      transform: translate(-10px);
    }

    80% {
      transform: translate(5px);
    }

    100% {
      transform: translate(0px);
    }
  }

  .inputText.err::placeholder,
  .twoinputconteiner .inputText.err::placeholder {
    color: #FFFFFF;
  }

  body {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    max-height: stretch;
    max-height: -webkit-fill-available;
  }

  html {
    height: stretch;
    height: -webkit-fill-available;
  }


  header {
    background-color: #000;
    padding: 1vh;
    display: flex;
    justify-content: center;
  }



  .HomeMain {
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Chivo', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    color: #E3B225;
    text-align: center;
  }


  header img {
    height: 13vh;
  }

  header a {
    height: 13vh;
  }


  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  a {
    margin: 0;
    padding: 0;
  }



  .subTitle {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: #ffffff;
    font-size: 1.25rem;

    text-align: center;
  }

  @media (min-width: 768px) {

    h1,
    .h1 {
      font-size: 4rem;
    }


  }



  .CallToAction {
    padding: 1rem 4rem;
    font-size: 1.25rem;
    border-radius: 0.2rem;
    border: #F5BD33 solid 1px;
    background-color: rgba(33, 33, 33, .5);
    font-family: 'Akshar', sans-serif;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }



  footer {
    min-height: 13vh;
    padding-top: 2vh;
    height: auto;
    background-color: #000;
    color: #fff;
    box-shadow: 0px -5px 12px 0px #AE891E33;

  }

  .footerConteiner {
    background: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
  }

  .footerConteiner .column {
    flex-basis: 30%;
    display: flex;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: 'Akshar', sans-serif;


  }

  .footerConteiner .column img {

    width: 24px;
    height: 24px;
    margin: 10px;
    margin-top: 0px;
  }

  .footerConteiner .column a {
    color: #ffffff;
  }

  .footerConteiner .column b {
    font-weight: 300;
  }

  .column p {
    margin: 0;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: #ffffff;
    font-size: 1em;
  }

  @media(max-width: 768px) {
    .footerConteiner .column {
      flex-basis: 100%;

    }
  }

  .MainTurnos {

    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;

  }



  .ProgressBarConteiner {
    width: 90%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7.5%;

  }

  h2 {
    font-size: 1.75rem;
    color: #F5BD33;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
    text-align: center;
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.75rem;
    color: #F5BD33;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
  }


  h3.TitleTurnos {
    text-align: center;
    color: #fff;
    border-bottom: #AE891E 1px solid;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5px;
    font-size: 1.75rem;
    white-space: nowrap;
  }

  .TurnoMainSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }





  .numberBlock {
    width: 45vw;
    display: flex;
    justify-content: space-between;
  }

  .numberProgressBar {
    padding: 0;
    height: 9vw;
    width: 9vw;
    border-radius: 100%;
    border: 2px #E3B225 solid;
    background: #121212;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.35rem;
  }


  .numberProgressBar.Active {
    background-color: #E3B225;
    color: #000;
  }

  .numberProgressBar.Finish {
    border: solid 2px #AE891E;
    background-color: #AE891E;
  }

  .numberProgressBar .FinishEfect {
    opacity: 0%;
    transition: all .45s;
    position: absolute;

  }

  .numberProgressBar.Finish .FinishEfect {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #AE891E;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100%;
  }

  .FinishEfect img {
    width: 50%;
    height: auto;
  }


  .lineOutside {
    position: absolute;
    height: 2px;
    transform: translate(100%, 100%);
    width: 100%;
    background-color: #E3B225;
  }

  .nameProgressBarButton {
    font-family: 'Akshar';
    position: absolute;
    max-height: 5px;
    transform: translate(0%, 800%);
    color: #fff;
  }



  .conteinerBottons {
    width: 100%;
    height: 10.5vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .conteinerBottons.confirmation {
    width: 100%;
    height: 15.5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }


  .conteinerBottons.confirmation button {
    margin: 0 auto;
    padding: 0;
    width: 90%;
    height: 40%;
    font-family: 'Akshar';
    text-align: center;
    font-size: 1rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .conteinerBottons button {
    margin: 0 auto;
    padding: 0;
    width: 45%;
    height: 55%;
    font-family: 'Akshar';
    text-align: center;
    font-size: 1rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .conteinerBottons button a {
    width: 100%;
    height: 100%;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .conteinerBottons button.back {
    border: 1px #fff solid;
    border-radius: 3.2px;
  }

  .conteinerBottons button.next {
    border: 1px #E3B225 solid;
    border-radius: 3.2px;
    transition: all 0.15s;
  }

  .next:hover {
    background-color: #E3B225;
    color: black;
  }

  .contienerSchedule {
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;

    width: 100%;
    height: 60vh;
    display: grid;
    grid-template-columns: repeat(3, 29.75%);
    grid-template-rows: repeat(8, 10.5%);
    grid-column-gap: 5.375%;
    grid-row-gap: 2.25%;
    justify-content: center;
  }

  .contienerSchedule button {
    border: #E3B225 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Chivo', sans-serif;
    cursor: pointer;
    background: none;
    font-size: .95rem;
    border-radius: 3.2px;
  }

  .contienerSchedule button:disabled {
    background-color: #898989;
    border: 1px solid #898989;
    color: #B7B7B7;
    cursor: default;
  }

  .contienerSchedule button.Select:disabled {
    background: none;
    background-color: #898989;
    border: 1px solid #898989;
    color: #B7B7B7;
    cursor: default;
  }

  .contienerSchedule button.Select {
    background: linear-gradient(0deg, #E3B225, #E3B225),
      linear-gradient(0deg, #CC9E17, #CC9E17);
    color: #121212;
  }

  .continerForm {
    margin-top: 2.5%;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: none;
  }

  .conteinerFormMovil {
    margin-top: 2.5%;
    margin-bottom: 2.5vh;

    height: 75%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .inputText {
    width: 92.5%;
    height: 6.5vh;
    border: #E3B225 1px solid;
    background: none;
    font-family: 'Akshar', sans-serif;
    padding-left: 2.5%;
    padding-right: 2.5%;
    font-size: 1.1rem;
    color: #fff;
    margin-bottom: 5%;

  }

  .twoinputconteiner {
    width: 95.5%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5%;

  }

  .twoinputconteiner .inputText {
    width: 100%;
    height: 100%;
    border: #E3B225 1px solid;
    background: none;
    font-family: 'Akshar', sans-serif;
    font-size: 1.1rem;
    color: #fff;

  }


  .continerForm label,
  .conteinerFormMovil label {
    color: #fff;
    width: 92.5%;
    font-family: 'Akshar', sans-serif;
    text-align: start;
    margin-bottom: 1%;
    white-space: nowrap;
  }



  .continerInput {
    height: 6.5vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .continerInput label {
    position: absolute;
    transform: translateY(-120%);



  }

  .bookingDetail {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
    font-family: 'Akshar', sans-serif;
  }

  h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;

    text-align: center;
    font-size: 1.17em;
    margin: 0;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 400;
    text-align: start;
    width: 92.5%;
    margin-top: 5%;
    margin-bottom: 1.5%;
  }


  .conteinerCheckbox {
    width: 90%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 1vh;

  }



  .conteinerCheckbox .checkbox {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 5px;
    appearance: none;
    border: 2px solid #fff;
    position: relative;
  }

  .conteinerCheckbox .checkbox:checked:after {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    content: '';
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
  }


  .untiCheboxAndLabel {
    margin-top: 1vh;
    margin-bottom: 1vh;
    background: none;
    border: none;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;

  }

  .conteinerCheckbox label {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin: 0;
    width: auto;
    padding-left: 5%;
  }

  .bookingDetail {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
    font-family: 'Akshar', sans-serif;
  }

  h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;

    text-align: center;
    font-size: 1.17em;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 500;
    line-height: 18px;
  }

  .Aclaracion {
    width: 100%;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    margin-bottom: 5%;
  }

  .cardConfirm {
    padding-top: 5%;
    padding-bottom: 5%;
    max-width: 450px;
    margin-top: 2.5%;
    width: 90%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    height: auto;
    border: 1px solid #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .KeyWord {
    margin-top: 5.5%;
    font-size: 2.5rem;
    font-family: Akshar;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
  }

  .confirmDetalle {
    color: #FFFFFF;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    max-width: 90%;
  }

  .ConfirmTitle {
    font-family: Akshar;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    color: #FFFFFF;
    margin: 1%;
  }

  .conteinerRef {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
  }

  .circleRefConteiner {
    font-family: 'Akshar';
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 1vh;

  }

  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 2.5%;
  }

  .available .circle {
    border: #E3B225 1px solid;
  }

  .disabled .circle {
    background: linear-gradient(0deg, #898989, #898989),
      linear-gradient(0deg, rgba(184, 184, 184, 0.2), rgba(184, 184, 184, 0.2));
    border: 1px solid #898989
  }

  .Select .circle {
    background: #E3B225;
    border: 1px solid #E3B225;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {

  .inputText.err,
  .twoinputconteiner .inputText.err {
    border: #bb1313 1px solid;
    animation: ShakeOnError 0.4s linear;
  }

  @keyframes ShakeOnError {
    0% {
      transform: translate(20px);
    }

    20% {
      transform: translate(-20px);
    }

    40% {
      transform: translate(10px);
    }

    60% {
      transform: translate(-10px);
    }

    80% {
      transform: translate(5px);
    }

    100% {
      transform: translate(0px);
    }
  }

  .inputText.err::placeholder,
  .twoinputconteiner .inputText.err::placeholder {
    color: #FFFFFF;
  }

  body {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    max-height: stretch;
    max-height: -webkit-fill-available;
  }

  html {
    height: stretch;
    height: -webkit-fill-available;
  }


  header {
    background-color: #000;
    padding: 1vh;
    display: flex;
    justify-content: center;
  }



  .HomeMain {
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Chivo', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    color: #E3B225;
    text-align: center;
  }


  header img {
    height: 13vh;
  }

  header a {
    height: 13vh;
  }


  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  a {
    margin: 0;
    padding: 0;
  }



  .subTitle {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: #ffffff;
    font-size: 1.25rem;

    text-align: center;
  }

  @media (min-width: 768px) {

    h1,
    .h1 {
      font-size: 4rem;
    }


  }



  .CallToAction {
    padding: 1rem 4rem;
    font-size: 1.25rem;
    border-radius: 0.2rem;
    border: #F5BD33 solid 1px;
    background-color: rgba(33, 33, 33, .5);
    font-family: 'Akshar', sans-serif;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }



  footer {
    min-height: 13vh;
    padding-top: 2vh;
    height: auto;
    background-color: #000;
    color: #fff;
    box-shadow: 0px -5px 12px 0px #AE891E33;

  }

  .footerConteiner {
    background: #000;
    background: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
  }

  .footerConteiner .column {
    flex-basis: 30%;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: 'Akshar', sans-serif;


  }

  .footerConteiner .column img {

    width: 24px;
    height: 24px;
    margin: 10px;
    margin-top: 0px;
  }

  .footerConteiner .column a {
    color: #ffffff;
  }

  .footerConteiner .column b {
    font-weight: 300;
  }

  .column p {
    margin: 0;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: #ffffff;
    font-size: 1em;
  }

  @media(max-width: 768px) {
    .footerConteiner .column {
      flex-basis: 100%;

    }
  }

  .MainTurnos {

    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;

  }



  .ProgressBarConteiner {
    width: 90%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;

  }

  h2 {
    font-size: 1.75rem;
    color: #F5BD33;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
    text-align: center;
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.75rem;
    color: #F5BD33;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
  }


  h3.TitleTurnos {
    text-align: center;
    color: #fff;
    border-bottom: #AE891E 1px solid;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5px;
    font-size: 1.75rem;
    white-space: nowrap;
  }

  .TurnoMainSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



  .react-calendar {
    max-width: 550px;
    margin-bottom: 5vh;
  }

  .numberBlock {
    width: 25vw;
    display: flex;
    justify-content: space-between;
  }

  .numberProgressBar {
    padding: 0;
    height: 5vw;
    width: 5vw;
    border-radius: 100%;
    border: 2px #E3B225 solid;
    background: #121212;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.35rem;
  }


  .numberProgressBar.Active {
    background-color: #E3B225;
    color: #000;
  }

  .numberProgressBar.Finish {
    border: solid 2px #AE891E;
    background-color: #AE891E;
  }

  .numberProgressBar .FinishEfect {
    opacity: 0%;
    transition: all .45s;
    position: absolute;

  }

  .numberProgressBar.Finish .FinishEfect {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #AE891E;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100%;
  }

  .FinishEfect img {
    width: 50%;
    height: auto;
  }


  .lineOutside {
    position: absolute;
    height: 2px;
    transform: translate(100%, 100%);
    width: 100%;
    background-color: #E3B225;
  }

  .nameProgressBarButton {
    font-family: 'Akshar';
    position: absolute;
    max-height: 5px;
    transform: translate(0%, 800%);
    color: #fff;
  }



  .conteinerBottons {
    width: 80%;
    height: 10.5vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .conteinerBottons.confirmation {
    width: 100%;
    height: 15.5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
  }


  .conteinerBottons.confirmation button {
    margin: 0 auto;
    padding: 0;
    width: 90%;
    height: 40%;
    font-family: 'Akshar';
    text-align: center;
    font-size: 1rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .conteinerBottons button {
    margin: 0 auto;
    padding: 0;
    width: 45%;
    height: 55%;
    font-family: 'Akshar';
    text-align: center;
    font-size: 1rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .conteinerBottons button a {
    width: 100%;
    height: 100%;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .conteinerBottons button.back {
    border: 1px #fff solid;
    border-radius: 3.2px;
  }


  .conteinerBottons button.next {
    border: 1px #E3B225 solid;
    border-radius: 3.2px;
    transition: all 0.15s;
  }

  .next:hover {
    background-color: #E3B225;
    color: black;
  }


  .contienerSchedule {
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;

    width: 80%;
    height: 35vh;
    display: grid;
    grid-template-columns: repeat(6, 12.75%);
    grid-template-rows: repeat(4, 17.5%);
    grid-column-gap: 4.7%;
    grid-row-gap: 7.5%;
    justify-content: center;
    justify-content: center;
  }

  .contienerSchedule button {
    border: #E3B225 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Chivo', sans-serif;
    cursor: pointer;
    background: none;
    font-size: .95rem;
    border-radius: 3.2px;
  }

  .contienerSchedule button:disabled {
    background-color: #898989;
    border: 1px solid #898989;
    color: #B7B7B7;
    cursor: default;
  }

  .contienerSchedule button.Select:disabled {
    background: none;
    background-color: #898989;
    border: 1px solid #898989;
    color: #B7B7B7;
    cursor: default;
  }

  .contienerSchedule button.Select {
    background: linear-gradient(0deg, #E3B225, #E3B225),
      linear-gradient(0deg, #CC9E17, #CC9E17);
    color: #121212;
  }

  .conteinerFormMovil {
    margin-top: 2.5%;
    margin-bottom: 2.5vh;

    height: 75%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    display: none;
  }

  .continerForm {
    margin-top: 2.5%;
    height: 35vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3.5vh;

  }

  .inputText {
    width: 87.5%;
    height: 17.5%;
    border: #E3B225 1px solid;
    background: none;
    font-family: 'Akshar', sans-serif;
    padding-left: 2.5%;
    padding-right: 2.5%;
    font-size: 1.1rem;
    color: #fff;
  }

  .twoinputconteiner {
    width: 92.5%;
    height: 17.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5%;

  }

  .twoinputconteiner .inputText {
    width: 100%;
    height: 100%;
    border: #E3B225 1px solid;
    background: none;
    font-family: 'Akshar', sans-serif;
    font-size: 1.1rem;
    color: #fff;

  }


  .continerForm label {
    color: #fff;
    width: 92.5%;
    font-family: 'Akshar', sans-serif;
    text-align: start;
    margin-bottom: 1%;

  }



  .continerInput {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 47.5%;
    position: relative;
  }

  .continerInput label {
    position: absolute;
    transform: translateY(-120%);



  }





  .bookingDetail {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
    font-family: 'Akshar', sans-serif;
  }

  h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;

    text-align: center;
    font-size: 1.17em;
    margin: 0;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 400;
    text-align: start;
    width: 92.5%;
    margin-top: 5%;
    margin-bottom: 1.5%;
  }


  .conteinerCheckbox {
    width: 90%;
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

  }



  .conteinerCheckbox .checkbox {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 5px;
    appearance: none;
    border: 2px solid #fff;
    position: relative;
  }

  .conteinerCheckbox .checkbox:checked:after {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    content: '';
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
  }


  .untiCheboxAndLabel {
    margin-top: 1vh;
    margin-bottom: 1vh;
    background: none;
    border: none;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
  }

  .conteinerCheckbox label {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin: 0;
    width: auto;
    padding-left: 5%;
  }

  .bookingDetail {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
    font-family: 'Akshar', sans-serif;
  }

  h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;

    text-align: center;
    font-size: 1.17em;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 500;
    line-height: 18px;
  }

  .Aclaracion {
    width: 100%;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    margin-bottom: 5%;
  }

  .cardConfirm {
    padding-top: 5%;
    padding-bottom: 5%;
    max-width: 450px;
    margin-top: 2.5%;
    width: 90%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    height: auto;
    border: 1px solid #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .KeyWord {
    margin-top: 5.5%;
    font-size: 2.5rem;
    font-family: Akshar;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
  }

  .confirmDetalle {
    color: #FFFFFF;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    max-width: 90%;
  }

  .ConfirmTitle {
    font-family: Akshar;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    color: #FFFFFF;
    margin: 1%;
  }

  .conteinerRef {
    width: 80%;
    height: 25%;
    display: flex;
  }

  .circleRefConteiner {
    font-family: 'Akshar';
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 1vh;

  }

  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 2.5%;
  }

  .available .circle {
    border: #E3B225 1px solid;
  }

  .disabled .circle {
    background: linear-gradient(0deg, #898989, #898989),
      linear-gradient(0deg, rgba(184, 184, 184, 0.2), rgba(184, 184, 184, 0.2));
    border: 1px solid #898989
  }

  .Select .circle {
    background: #E3B225;
    border: 1px solid #E3B225;
  }
}

@media (min-width: 1201px) {


  h3.progressBarTitle {
    color: #E3B225;
    font-family: 'Chivo';
    font-size: 1.5rem;
  }

  .conteinerFormMovil {
    display: none;
  }

  body .estrecho {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    max-height: stretch;
    max-height: -webkit-fill-available;
  }

  html {
    height: stretch;
    height: -webkit-fill-available;
  }


  header {
    background-color: #000;
    padding: 1vh;
    display: flex;
    justify-content: center;
  }

  header img {
    height: 13vh;
  }

  header a {
    height: 13vh;
  }

  .HomeMain {
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Chivo', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    color: #E3B225;
    text-align: center;
  }

  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  a {
    margin: 0;
    padding: 0;
  }



  .subTitle {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: #ffffff;
    font-size: 1.25rem;

    text-align: center;
  }

  @media (min-width: 768px) {

    h1,
    .h1 {
      font-size: 4rem;
    }


  }



  .CallToAction {
    padding: 1rem 4rem;
    font-size: 1.25rem;
    border-radius: 0.2rem;
    border: #F5BD33 solid 1px;
    background-color: rgba(33, 33, 33, .5);
    font-family: 'Akshar', sans-serif;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }



  footer {
    padding-top: 2vh;
    height: 13vh;

    background-color: #000;
    color: #fff;
    box-shadow: 0px -5px 12px 0px #AE891E33;

  }

  .footerConteiner {
    background: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
    margin-top: 15px;
  }

  .footerConteiner .column {
    flex-basis: 30%;
    justify-content: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: 'Akshar', sans-serif;


  }

  .footerConteiner .column img {

    width: 24px;
    height: 24px;
    margin: 10px;
    margin-top: 0px;
  }

  .footerConteiner .column a {
    color: #ffffff;
  }

  .footerConteiner .column b {
    font-weight: 300;
  }

  .column p {
    margin: 0;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: #ffffff;
    font-size: 1em;
  }

  @media(max-width: 768px) {
    .footerConteiner .column {
      flex-basis: 100%;

    }
  }

  .MainTurnos {
    min-height: 70vh;
    background-color: #121212;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .ProgressBarConteiner {
    width: 20%;
    height: 90%;
  }

  h2 {
    font-size: 1.75rem;
    color: #F5BD33;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
  }

  h3 {
    font-size: 1.75rem;
    color: #F5BD33;
    font-weight: 500;
    font-family: 'Chivo', sans-serif;
  }


  h3.TitleTurnos {
    text-align: center;
    color: #fff;
    border-bottom: #AE891E 1px solid;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5px;
    font-size: 2r7m;
  }

  .TurnoMainSection {
    width: 42.5%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .numberBlock {
    height: 33vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .numberProgressBar {
    padding: 0;
    height: 7.5vh;
    width: 7.5vh;
    border-radius: 100%;
    border: 2px #E3B225 solid;
    background: #121212;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.35rem;
  }


  .numberProgressBar.Active {
    background-color: #E3B225;
    color: #000;
  }

  .numberProgressBar.Finish {
    border: solid 2px #AE891E;
    background-color: #AE891E;
  }

  .numberProgressBar .FinishEfect {
    opacity: 0%;
    transition: all .45s;
    position: absolute;

  }

  .numberProgressBar.Finish .FinishEfect {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #AE891E;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100%;
  }

  .FinishEfect img {
    width: 50%;
    height: auto;
  }


  .lineOutside {
    position: absolute;
    height: 100%;
    transform: translate(-1px, 100%);
    width: 2px;
    background-color: #E3B225;
  }

  .nameProgressBarButton {
    font-family: 'Akshar';
    position: absolute;
    max-width: 5px;
    transform: translate(1100%, 0px);
    color: #fff;
  }



  .conteinerBottons {
    width: 95%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 2vh;
  }

  .conteinerBottons button {
    margin: 0 auto;
    padding: 0;
    width: 45%;
    height: 45px;
    font-family: 'Akshar';
    text-align: center;
    font-size: 1rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .conteinerBottons button a {
    width: 100%;
    height: 100%;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .conteinerBottons button.back {
    border: 1px #fff solid;
    border-radius: 3.2px;
  }


  .conteinerBottons button.next {
    border: 1px #E3B225 solid;
    border-radius: 3.2px;
    transition: all 0.15s;
  }

  .next:hover {
    background-color: #E3B225;
    color: black;
  }



  .contienerSchedule {
    width: 100%;
    height: 30vh;
    display: grid;
    grid-template-columns: repeat(6, 12.75%);
    grid-template-rows: repeat(4, 17.5%);
    grid-column-gap: 4.7%;
    grid-row-gap: 7.5%;
    justify-content: center;
  }

  .contienerSchedule button {
    border: #E3B225 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Chivo', sans-serif;
    cursor: pointer;
    background: none;
    font-size: .95rem;
    border-radius: 3.2px;
  }

  .contienerSchedule button:disabled {
    background-color: #898989;
    border: 1px solid #898989;
    color: #B7B7B7;
    cursor: default;
  }

  .contienerSchedule button.Select:disabled {
    background: none;
    background-color: #898989;
    border: 1px solid #898989;
    color: #B7B7B7;
    cursor: default;
  }

  .contienerSchedule button.Select {
    background: linear-gradient(0deg, #E3B225, #E3B225),
      linear-gradient(0deg, #CC9E17, #CC9E17);
    color: #121212;
  }

  .continerForm {
    margin-top: 2.5%;
    height: 35vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .inputText {
    width: 87.5%;
    height: 17.5%;
    border: #E3B225 1px solid;
    background: none;
    font-family: 'Akshar', sans-serif;
    padding-left: 2.5%;
    padding-right: 2.5%;
    font-size: 1.1rem;
    color: #fff;
  }

  .twoinputconteiner {
    width: 92.5%;
    height: 17.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5%;

  }

  .twoinputconteiner .inputText {
    width: 95%;
    height: 100%;
    border: #E3B225 1px solid;
    background: none;
    font-family: 'Akshar', sans-serif;
    font-size: 1.1rem;
    color: #fff;
    transition: all 0.2s;
  }

  .inputText.err,
  .twoinputconteiner .inputText.err {
    border: #bb1313 1px solid;
    animation: ShakeOnError 0.4s linear;
  }

  @keyframes ShakeOnError {
    0% {
      transform: translate(20px);
    }

    20% {
      transform: translate(-20px);
    }

    40% {
      transform: translate(10px);
    }

    60% {
      transform: translate(-10px);
    }

    80% {
      transform: translate(5px);
    }

    100% {
      transform: translate(0px);
    }
  }

  .inputText.err::placeholder,
  .twoinputconteiner .inputText.err::placeholder {
    color: #FFFFFF;
  }

  .continerForm label {
    color: #fff;
    width: 92.5%;
    font-family: 'Akshar', sans-serif;
    text-align: start;
    margin-bottom: 1%;
  }



  .continerInput {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 47.5%;
    position: relative;
  }

  .continerInput label {
    position: absolute;
    transform: translateY(-120%);



  }

  .bookingDetail {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
    font-family: 'Akshar', sans-serif;
  }

  h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;

    text-align: center;
    font-size: 1.17em;
    margin: 0;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 400;
    text-align: start;
    width: 92.5%;
    margin-top: 1%;

  }

  .conteinerCheckbox {
    width: 90%;
    height: 15%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1%;
  }


  .conteinerCheckbox .checkbox:disabled {
    border: 2px solid #898989;
  }

  .conteinerCheckbox .checkbox {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 5px;
    appearance: none;
    border: 2px solid #fff;
    position: relative;
  }


  .conteinerCheckbox .checkbox:checked:after:disabled {
    background-color: #898989;
  }

  .conteinerCheckbox .checkbox:checked:after {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    content: '';
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
  }


  .untiCheboxAndLabel {
    margin-top: 1vh;
    margin-bottom: 1vh;
    background: none;
    border: none;
    width: 33%;
    height: auto;
    display: flex;
    align-items: center;
  }

  .conteinerCheckbox label {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin: 0;
    width: auto;
    padding-left: 1%;
    color: #fff;

  }

  .conteinerCheckbox label.disabled {
    color: #898989;
  }


  .bookingDetail {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    font-weight: 500;
    line-height: 18px;
    color: #fff;
    font-family: 'Akshar', sans-serif;
  }

  h4 {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;

    text-align: center;
    font-size: 1.17em;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 500;
    line-height: 18px;
  }

  .Aclaracion {
    width: 90%;
    color: #fff;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
  }

  .cardConfirm {
    margin-top: 2.5%;
    width: 100%;
    height: auto;
    border: 1px solid #FFFFFF;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .KeyWord {
    font-size: 2.5rem;
    font-family: Akshar;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
  }

  .confirmDetalle {
    color: #FFFFFF;
    font-family: 'Akshar', sans-serif;
    font-weight: 300;
    font-size: 1.23rem;
  }

  .ConfirmTitle {
    font-family: Akshar;
    font-size: 1.1rem;
    font-weight: 300;
    text-align: center;
    color: #FFFFFF;
    margin: 1%;
  }

  .conteinerRef {
    width: 100%;
    height: 15%;
    display: flex;

  }

  .circleRefConteiner {
    font-family: 'Akshar';
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #FFFFFF;

  }

  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .available .circle {
    border: #E3B225 1px solid;
  }

  .disabled .circle {
    background: linear-gradient(0deg, #898989, #898989),
      linear-gradient(0deg, rgba(184, 184, 184, 0.2), rgba(184, 184, 184, 0.2));
    border: 1px solid #898989
  }

  .Select .circle {
    background: #E3B225;
    border: 1px solid #E3B225;
  }
}

.CallToAction.index {
  transition: all 0.15s;
}

.CallToAction.index:hover {
  background-color: #E3B225;
  color: black;
}

.CallToAction.index:hover path {
  fill: #000;
}

.errorMain {
  height: 70vh;
  background-color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorTitle {
  font-family: 'chivo';
  font-size: 3rem;
}


.errorDecription {
  margin: 0;
  margin-bottom: 2vh;
  font-size: 1.5rem;
  color: #FFFFFF;
}

.MainTurnos.loading {
  height: 70vh;
}



.errorBackEfect:hover {
  background-color: #E3B225;
  color: black;
}


.errorBackEfect {
  background-color: #E3B225;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateX(100%);
  transition: all 0.35s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

button.next:disabled {
  color: #fff;
  cursor: default;
}


.conteinerBottons button.next.googleBrand {
  font-family: 'Product Sans';
  background-color: #FFFFFF;
  color: #000;
  border: 1px solid #fff;
}

.conteinerBottons button.next.googleBrand img {
  height: 50%;
  margin-right: 2%;
}