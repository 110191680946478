.react-calendar {
    background-color: #121212;
    color: #fff;
    border: none;
    font-family: 'Chivo', sans-serif;
    width: 75%;
}

@media (max-width: 650px) {
    .react-calendar {
        background-color: #121212;
        color: #fff;
        border: none;
        font-family: 'Chivo', sans-serif;
        width: 100%;
    }
}

.react-calendar__navigation button {
    font-size: 1.4rem;
}


.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
    color: #fff;
    background-color: #121212;
}

.react-calendar__tile.react-calendar__month-view__days__day {
    color: #fff;
}

.react-calendar__tile.react-calendar__month-view__days__day:hover {
    background-color: #AE891E;
    color: #121212;
}

.react-calendar__month-view__days__day--weekend {
    color: #ffffff;
}

.react-calendar__navigation__arrow {
    color: #fff;
}

.react-calendar__navigation button:disabled {
    color: #615f5f;
    background-color: #121212;
}

.react-calendar__navigation__label {
    color: #fff;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    color: #121212;
    background-color: #AE891E;
}


.react-calendar__tile:disabled {
    background-color: #121212;
    color: #615f5f;
}

.react-calendar__tile:disabled:hover {
    background-color: #121212;
    color: #615f5f;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    background-color: #E3B225;
    color: #121212;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: #e3b225;
}

.react-calendar__tile:disabled.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: #615f5f;
}

.react-calendar__tile:disabled.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth:hover {
    color: #615f5f;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth:hover {
    color: #121212;
}




.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-family: 'Chivo', sans-serif;
}


.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
    background-color: #E3B225;
    color: #121212;
    font-family: 'Chivo', sans-serif;
}

.react-calendar__tile.react-calendar__year-view__months__month {
    color: #fff;
    font-family: 'Chivo', sans-serif;
}

.react-calendar__tile:disabled.react-calendar__year-view__months__month {
    color: #615f5f;
    font-family: 'Chivo', sans-serif;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #AE891E;
    color: #121212;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    font-family: 'Chivo', sans-serif;
    color: #fff;
    font-size: 1rem;
}

.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
    background-color: #e3b225;
    color: #121212;
}


.react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month {
    color: #000;
}